$type-colors: (
        string: var(--ngx-json-string, #FF6B6B),
        number: var(--ngx-json-number, #009688),
        boolean: var(--ngx-json-boolean, #B938A4),
        date: var(--ngx-json-date, #05668D),

        array: var(--ngx-json-array, #999),
        object: var(--ngx-json-object, #999),
        function: var(--ngx-json-function, #999),

        'null': var(--ngx-json-null, #fff),
        undefined: var(--ngx-json-undefined, #fff)
);

.ngx-json-viewer {
  font-family: var(--ngx-json-font-family, monospace);
  font-size: var(--ngx-json-font-size, 1em);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .segment {
    padding: 2px;
    margin: 1px 1px 1px 12px;

    .segment-main {
      word-wrap: break-word;

      .toggler {
        position: absolute;
        margin-left: -14px;
        margin-top: 3px;
        font-size: .8em;
        line-height: 1.2em;
        vertical-align: middle;
        color: var(--ngx-json-toggler, #787878);

        &::after {
          display: inline-block;
          content: "►";
          transition: transform 0.1s ease-in;
        }
      }

      .segment-key {
        color: var(--ngx-json-key, #4E187C);
      }

      .segment-separator {
        color: var(--ngx-json-separator, #999);
      }

      .segment-value {
        color: var(--ngx-json-value, #000);
      }
    }

    .children {
      margin-left: 12px;
    }
  }

  @each $type, $color in $type-colors {
    .segment-type-#{$type} > .segment-main > .segment-value {
      color: #{$color};
    }
  }

  // special cases need highlighted
  .segment-type-null > .segment-main > .segment-value {
    background-color: var(--ngx-json-null-bg, red);
  }
  .segment-type-undefined > .segment-main {
    & > .segment-key {
      color: var(--ngx-json-undefined-key, #999);
    }
    & > .segment-value {
      background-color: var(--ngx-json-undefined-key, #999);
    }
  }
  .segment-type-object > .segment-main,
  .segment-type-array > .segment-main {
    white-space: nowrap;
  }

  .expanded > .toggler::after {
    transform: rotate(90deg);
  }

  .expandable,
  .expandable > .toggler {
    cursor: pointer;
  }
}